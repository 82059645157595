import { AppModule } from 'app/module';
import { smoothScrollInsideElement, smoothScrollTo } from 'util/scroll';
import Template from './template.hbs';
import './styles.scss';

export class Section extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    getPropsFromDom() {
        return {
            insideIframe: (this.dom.el.getAttribute('data-inside-iframe') === 'true'),
            isIE: 'objectFit' in document.documentElement.style === false,
        };
    }

    subEvents() {
        this.getChilds().forEach((child) => {
            child.events.on('scrollToSection', (event) => this.scrollToSection(event));
        });
    }

    scrollToSection(event) {
        let jobDetailContainer = false;
        const splitView = !window.parent.document.querySelector('.l-master__footer');
        const sectionPaddingTop = 15;

        if (splitView) {
            jobDetailContainer = window.parent.document.querySelector('.m-jobDetail__container');
        } else {
            jobDetailContainer = window.parent.document.documentElement;
        }

        const scrollContainer = this.props.insideIframe && jobDetailContainer
            ? jobDetailContainer : window;

        if (!this.props.insideIframe || jobDetailContainer === null) {
            let offset = this.props.insideIframe ? 15 : 70; // default for sticky nav offset

            if (event.arguments && event.arguments.length > 0) {
                offset = event.arguments[0]; // eslint-disable-line prefer-destructuring
            }

            const sectionOffset = this.dom.el.getBoundingClientRect().top - offset;
            const targetPosition = scrollContainer.pageYOffset + sectionOffset;

            if (sectionOffset < 0) {
                smoothScrollTo(targetPosition, 500);
            }
        } else {
            const offsetIFrame2ScrollContainer = scrollContainer.querySelector('.m-jobContent').offsetTop;
            const offsetSection2IFrame = this.dom.el.getBoundingClientRect().top;
            const jobHeader = scrollContainer.querySelector('.m-jobHeader__inner');
            const jobHeaderHeight = jobHeader.classList.contains('m-jobHeader__inner--sticky')
                && !this.props.isIE ? jobHeader.clientHeight : 0;

            const isOutsideViewport = scrollContainer.scrollTop
                - ((offsetSection2IFrame + offsetIFrame2ScrollContainer) - jobHeaderHeight) > 0;

            if (isOutsideViewport) {
                smoothScrollInsideElement(
                    scrollContainer,
                    scrollContainer.scrollTop,
                    (offsetSection2IFrame + offsetIFrame2ScrollContainer) - jobHeaderHeight - sectionPaddingTop,
                    500,
                );
            }
        }
    }
}
