var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"m-companyLocation__link\"\n                   href=\"https://www.google.com/maps/dir/?api=1&destination="
    + alias4(((helper = (helper = lookupProperty(helpers,"lat") || (depth0 != null ? lookupProperty(depth0,"lat") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lat","hash":{},"data":data,"loc":{"start":{"line":9,"column":76},"end":{"line":9,"column":83}}}) : helper)))
    + ","
    + alias4(((helper = (helper = lookupProperty(helpers,"lng") || (depth0 != null ? lookupProperty(depth0,"lng") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lng","hash":{},"data":data,"loc":{"start":{"line":9,"column":84},"end":{"line":9,"column":91}}}) : helper)))
    + "\"\n                target=\"_blank\" rel=\"noopener\">&mdash; Route berechnen</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"m-companyLocation__link\"\n                   href=\"https://www.google.com/maps/dir/?api=1&destination="
    + alias4(((helper = (helper = lookupProperty(helpers,"mapsStreet") || (depth0 != null ? lookupProperty(depth0,"mapsStreet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mapsStreet","hash":{},"data":data,"loc":{"start":{"line":13,"column":76},"end":{"line":13,"column":90}}}) : helper)))
    + ","
    + alias4(((helper = (helper = lookupProperty(helpers,"mapsAddress") || (depth0 != null ? lookupProperty(depth0,"mapsAddress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mapsAddress","hash":{},"data":data,"loc":{"start":{"line":13,"column":91},"end":{"line":13,"column":106}}}) : helper)))
    + "\"\n                target=\"_blank\" rel=\"noopener\">&mdash; Route berechnen</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"m-companyLocation__telephone\" data-event=\"click: phone-number\" href=\"tel:"
    + alias4(((helper = (helper = lookupProperty(helpers,"phoneRaw") || (depth0 != null ? lookupProperty(depth0,"phoneRaw") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phoneRaw","hash":{},"data":data,"loc":{"start":{"line":21,"column":95},"end":{"line":21,"column":107}}}) : helper)))
    + "\">\n                T "
    + alias4(((helper = (helper = lookupProperty(helpers,"telephone") || (depth0 != null ? lookupProperty(depth0,"telephone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"telephone","hash":{},"data":data,"loc":{"start":{"line":22,"column":18},"end":{"line":22,"column":31}}}) : helper)))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"m-companyLocation__fax\">\n                F "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fax") || (depth0 != null ? lookupProperty(depth0,"fax") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fax","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":25}}}) : helper)))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-companyLocation__data m-companyLocation__data--bs\">\n    <div class=\"m-companyLocation__addressBlock m-companyLocation__addressBlock--bs\">\n        <h3 class=\"k-c-headline-xl m-companyLocation__name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"companyName") || (depth0 != null ? lookupProperty(depth0,"companyName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"companyName","hash":{},"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":75}}}) : helper)))
    + "</h3>\n        <p class=\"m-companyLocation__street\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"street") || (depth0 != null ? lookupProperty(depth0,"street") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"street","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":55}}}) : helper)))
    + "</p>\n        <p class=\"m-companyLocation__city\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"address") || (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":23}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCoordinates") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n    </div>\n\n    <div class=\"m-companyLocation__contactBlock\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"telephone") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fax") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});