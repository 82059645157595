import { AppModule } from 'app/module';
import { userCompanyFollow } from 'app/userCompanyFollow';
import 'module/icon/main';
import 'module/button/main';
import Template from './template.hbs';
import './styles.scss';

export class FollowButton extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            button: '.m-followButton__button',
            label: '.m-followButton__label',
        };
    }

    getPropsFromDom() {
        return {
            link: this.dom.button.classList.contains('m-followButton__button--link'),
            smallButton: this.dom.button.classList.contains('m-followButton__button--small'),
            isFollowed: this.dom.label.getAttribute('data-isfollowed') === 'true',
            theme: this.dom.button.classList.contains('m-followButton__button--agp'),
        };
    }

    ready() {
        userCompanyFollow.fetchAll();
        userCompanyFollow.events.on('onResponse', () => this.update());
        userCompanyFollow.events.on('onAddJob', () => this.update());
        userCompanyFollow.events.on('onRemoveJob', () => this.update());
    }

    domEvents() {
        this.dom.el.addEventListener('click', (e) => this.eventClicked(e));
    }

    eventClicked() {
        if (userCompanyFollow.isFollowed(this.props.id)) {
            userCompanyFollow.unfollowCompany(this.props.id);
        } else {
            userCompanyFollow.followCompany(this.props.id);
        }
    }

    update() {
        const isFollowed = userCompanyFollow.isFollowed(this.props.id);
        this.updateProps({
            isFollowed,
            theme: 'agp',
        }, true);
    }
}
