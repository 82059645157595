import { AppModule } from 'app/module';
import { JobCard } from 'module/jobCard/main';
import Template from './template.hbs';
import './styles.scss';

export class JobCardList extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            jobs: ['.m-jobCard'],
            itemsContainer: '.m-jobCardList__items',
        };
    }

    subs() {
        if (this.dom.jobs) {
            this.jobs = [];

            for (let i = 0; i < this.dom.jobs.length; i += 1) {
                this.jobs[i] = new JobCard(this.dom.jobs[i]);
            }
        }
    }

    getPropsFromDom() {
        return {
            itemsCount: this.dom.itemsContainer ? this.dom.itemsContainer.getAttribute('data-items-count') : '',
            slug: this.dom.itemsContainer ? this.dom.itemsContainer.getAttribute('data-slug') : '',
            active: !!this.dom.itemsContainer,
        };
    }
}
