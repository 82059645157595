import { AppModule } from 'app/module';
import { StaticMap } from 'module/staticMap/main';
import 'module/companyLocation/main';
import Template from './template.hbs';
import './styles.scss';

export class CompanyLocationMap extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            staticMap: '.m-companyLocationMap__staticMap .m-staticMap',
        };
    }

    subs() {
        this.staticMap = new StaticMap(this.dom.staticMap);
    }
}
