var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " m-companyLocation__data--spacing";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"m-companyLocation__link\"\n                   href=\"https://www.google.com/maps/dir/?api=1&destination="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lat") : depth0), depth0))
    + ","
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lng") : depth0), depth0))
    + "\"\n                target=\"_blank\" rel=\"noopener\">&mdash; Route berechnen</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"m-companyLocation__link\"\n                   href=\"https://www.google.com/maps/dir/?api=1&destination="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mapsStreet") : depth0), depth0))
    + ","
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mapsAddress") : depth0), depth0))
    + "\"\n                target=\"_blank\" rel=\"noopener\">&mdash; Route berechnen</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"m-companyLocation__telephone\" data-event=\"click: phone-number\">\n                T "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"telephone") : depth0), depth0))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"m-companyLocation__fax\">\n                F "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"fax") : depth0), depth0))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"m-companyLocation__linkContainer\">\n                <a class=\"m-companyLocation__link m-companyLocation__link--spacing\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"websiteLink") : depth0)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\"\n                   data-event=\"click: website-link\" target=\"_blank\" rel=\"noopener\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"websiteLink") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"customLink") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":43,"column":19}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"m-companyLocation__linkContainer\">\n                    <a class=\"m-companyLocation__link\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"customLink") : depth0)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\"\n                       target=\"_blank\" rel=\"noopener\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"customLink") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"socialMediaLinks") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":52,"column":15}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"m-companyLocation__socialMediaLinks\">\n"
    + ((stack1 = container.invokePartial(require("../socialMediaLinks/template.hbs"),depth0,{"name":"module/socialMediaLinks/template","hash":{"socialMediaLinks":(depth0 != null ? lookupProperty(depth0,"socialMediaLinks") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"m-companyLocation__buttonContainer\">\n            <a class=\"m-companyLocation__link\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"companyLocationListUrl") : depth0), depth0))
    + "\"\n               data-event=\"click: all-locations\">Weitere Standorte anzeigen</a>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-companyLocation__data"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insideIframe") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":95}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"m-companyLocation__addressBlock\">\n        <h3 class=\"k-c-headline-xl m-companyLocation__name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"companyName") : depth0), depth0))
    + "</h3>\n        <p class=\"m-companyLocation__street\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"street") : depth0), depth0))
    + "</p>\n        <p class=\"m-companyLocation__city\">\n            "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCoordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n    </div>\n\n    <div class=\"m-companyLocation__contactBlock\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"telephone") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fax") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"websiteLink") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTypeEmployerPagePlus") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTypeEmployerPagePlus") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"moreLocations") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":60,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});