import { AppModule } from 'app/module';
import { CompanyText } from 'module/companyText/main';
import { Video } from 'module/video/main';
import './styles.scss';
import Template from './template.hbs';

export class CompanyVideo extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            inner: '.m-companyVideo__container',
            companyText: '.m-companyText',
            video: '.m-video',
        };
    }

    getPropsFromDom() {
        return {
            active: !!this.dom.inner,
        };
    }

    subs() {
        if (this.dom.companyText) {
            this.companyText = new CompanyText(this.dom.companyText);
        }

        if (this.dom.video) {
            this.video = new Video(this.dom.video);
        }
    }

    subEvents() {
        if (this.companyText) {
            this.companyText.events.on('scrollToSection', () => {
                this.events.emit('scrollToSection');
            });
            this.companyText.events.on('updateIFrame', () => {
                this.events.emit('updateIFrame');
            });
        }

        this.propagateEvents(this.video, 'play');
    }
}
