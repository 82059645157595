import { AppModule } from 'app/module';
import { FollowButton } from 'module/followButton/main';
import { app } from 'app/app';
import Stickyfill from 'stickyfilljs';
import { getParams } from 'util/url';
import { mediaQueries } from 'util/mediaQueries';
import Template from './template.hbs';
import './styles.scss';

export class SubNavigation extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            shadowStart: '.m-subNavigation__shadow--start',
            shadowEnd: '.m-subNavigation__shadow--end',
            list: '.m-subNavigation__list',
            followButton: '.m-subNavigation__follow .m-followButton',
            links: ['.m-subNavigation__link'],
        };
    }

    domEvents() {
        this.dom.list.addEventListener('scroll', () => this.handleShadowVisibility());
        for (let i = 0; i < this.dom.links.length; i += 1) {
            this.dom.links[i].addEventListener('click', (e) => {
                this.events.emit('subNavigationLinkClicked', e);
            });
        }
    }

    getPropsFromDom() {
        return {
            id: this.dom.el.getAttribute('data-id'),
            hasAnchor: this.dom.el.getAttribute('data-anchor'),
        };
    }

    subs() {
        if (this.dom.followButton) {
            this.followButton = new FollowButton(this.dom.followButton);
        }
        if (this.dom.el.classList.contains('m-subNavigation--sticky')) {
            Stickyfill.add(this.dom.el);
        }
    }

    subsProps() {
        if (this.followButton) {
            this.followButton.updateProps({ id: this.props.id });
        }
    }

    scrollToActiveItem($activeItem) {
        const listWidth = this.dom.list.clientWidth;
        const itemWidth = $activeItem.clientWidth;

        this.dom.list.scrollLeft = $activeItem.offsetLeft - ((listWidth / 2) - (itemWidth / 2));
    }

    handleShadowVisibility() {
        const maxScrollStartReached = this.dom.list.scrollLeft <= 0;
        const maxScrollEndReached = Math.ceil(this.dom.list.scrollLeft)
            >= this.dom.list.scrollWidth - this.dom.list.offsetWidth;

        this.toggleShadow(this.dom.shadowStart, maxScrollStartReached);
        this.toggleShadow(this.dom.shadowEnd, maxScrollEndReached);
    }

    toggleShadow($el, maxScrollReached) {
        const shadowIsVisible = $el.classList.contains('m-subNavigation__shadow--visible');
        const showShadow = !maxScrollReached && !shadowIsVisible;
        const hideShadow = maxScrollReached && shadowIsVisible;

        // Using requestAnimationFrame for optimal scroll performance.
        // https://stackoverflow.com/a/44779316
        if (showShadow) {
            window.requestAnimationFrame(() => $el.classList.add('m-subNavigation__shadow--visible'));
        } else if (hideShadow) {
            window.requestAnimationFrame(() => $el.classList.remove('m-subNavigation__shadow--visible'));
        }
    }

    ready() {
        const $activeItem = this.dom.el.querySelector('.m-subNavigation__link--active');

        if ($activeItem && !mediaQueries.isDesktop()) {
            this.scrollToActiveItem($activeItem);
        }

        this.handleShadowVisibility();
        app.events.on('firstUserInteraction', () => {
            [].forEach.call(this.dom.links, (link) => {
                // hide-mf-header flag is set from SPECK when redirecting to k4. This flag shows a minified header
                // that should still be displayed after choosing another subnavigation entry
                const glue = link.href.indexOf('?') >= 0 ? '&' : '?';
                if (getParams(window.location.search)['hide-mf-header'] === 'true') {
                    link.href += `${glue}hide-mf-header=true`;
                }
                if (this.props.hasAnchor) link.href += '#content';
            });
        });
    }
}
