import { AppModule } from 'app/module';
import { WatchlistButton } from 'module/watchlistButton/main';
import Template from './template.hbs';
import 'module/spot/styles.scss';
import './styles.scss';

export class JobCard extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            watchlistButton: '.m-jobCard__watchlist .m-watchlistButton',
            unsolicitedApplication: '.m-jobCard__unsolicitedApplication',
        };
    }

    getPropsFromDom() {
        return {
            id: this.dom.el.getAttribute('data-id'),
            isSmartApplyEnabled: this.dom.el.getAttribute('data-is-smart-apply-enabled') === 'true',
        };
    }

    subs() {
        if (this.dom.watchlistButton) {
            this.watchlistButton = new WatchlistButton(this.dom.watchlistButton);
        }
    }

    subsProps() {
        if (this.watchlistButton) {
            this.watchlistButton.updateProps({ id: this.props.id });
        }
    }
}
