import { AppModule } from 'app/module';
import { CompanyEventItem } from 'module/companyEventItem/main';
import Template from './template.hbs';
import './styles.scss';

export class CompanyEventList extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            inner: '.m-companyEventList__inner',
            events: ['.m-companyEventItem'],
            toggleShowMore: '.m-companyEventList__toggleShowMore',
        };
    }

    getPropsFromDom() {
        return {
            active: !!this.dom.inner,
            showMore: false,
            hasMore: !!this.dom.toggleShowMore,
            color: this.dom.inner ? this.dom.inner.getAttribute('data-color') : '',
        };
    }

    subs() {
        if (this.dom.events) {
            this.events = [];
            const eventsProps = {};

            for (let i = 0; i < this.dom.events.length; i += 1) {
                this.events[i] = new CompanyEventItem(this.dom.events[i]);
                eventsProps[i] = this.events[i].getPropsFromDom();
            }
            this.props.events = eventsProps;
        }
    }

    domEvents() {
        if (this.dom.toggleShowMore) {
            this.dom.toggleShowMore.addEventListener('click', (e) => {
                this.toggleCompanyEventList(e);
            });
        }
    }

    toggleCompanyEventList(e) {
        if (e) e.preventDefault();
        this.updateProps({ showMore: !this.props.showMore }, true);
    }
}
