import { AppModule } from 'app/module';
import 'module/hr/main';
import Template from './template.hbs';
import './styles.scss';

export class CompanyEventItem extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            date: '.m-companyEventItem__date',
            title: '.m-companyEventItem__title',
            location: '.m-companyEventItem__location',
            link: '.m-companyEventItem__link',
        };
    }

    getPropsFromDom() {
        return {
            date: this.dom.date ? this.dom.date.innerText : '',
            title: this.dom.title ? this.dom.title.innerText : '',
            location: this.dom.location ? this.dom.location.innerText : '',
            link: this.dom.link ? this.dom.link.getAttribute('data-href') : '',
        };
    }
}
