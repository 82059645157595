var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"m-companyFactsBox__background\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brandColor") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"m-companyFactsBox__inner\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fontColor") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":98}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"missionStatement") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "\n            <dl class=\"m-companyFactsBox__list\">\n                <div class=\"m-companyFactsBox__item\">\n                    <dt class=\"m-companyFactsBox__description\">Gründungsjahr</dt>\n                    <dd class=\"m-companyFactsBox__value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"foundingYear") || (depth0 != null ? lookupProperty(depth0,"foundingYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"foundingYear","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":73}}}) : helper)))
    + "</dd>\n                </div>\n                <div class=\"m-companyFactsBox__item\">\n                    <dt class=\"m-companyFactsBox__description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"locationLabel") || (depth0 != null ? lookupProperty(depth0,"locationLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationLabel","hash":{},"data":data,"loc":{"start":{"line":23,"column":63},"end":{"line":23,"column":80}}}) : helper)))
    + "</dt>\n                    <dd class=\"m-companyFactsBox__value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"locations") || (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locations","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":70}}}) : helper)))
    + "</dd>\n                </div>\n                <div class=\"m-companyFactsBox__item\">\n                    <dt class=\"m-companyFactsBox__description\">Mitarbeiter*innen</dt>\n                    <dd class=\"m-companyFactsBox__value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"employees") || (depth0 != null ? lookupProperty(depth0,"employees") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"employees","hash":{},"data":data,"loc":{"start":{"line":28,"column":57},"end":{"line":28,"column":70}}}) : helper)))
    + "</dd>\n                </div>\n                <div class=\"m-companyFactsBox__item\">\n                    <dt class=\"m-companyFactsBox__description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currentJobCountLabel") || (depth0 != null ? lookupProperty(depth0,"currentJobCountLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentJobCountLabel","hash":{},"data":data,"loc":{"start":{"line":31,"column":63},"end":{"line":31,"column":87}}}) : helper)))
    + "</dt>\n                    <dd class=\"m-companyFactsBox__value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currentJobCount") || (depth0 != null ? lookupProperty(depth0,"currentJobCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentJobCount","hash":{},"data":data,"loc":{"start":{"line":32,"column":57},"end":{"line":32,"column":76}}}) : helper)))
    + "</dd>\n                </div>\n            </dl>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"m-companyFactsBox__color\" style=\"background: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"brandColor") || (depth0 != null ? lookupProperty(depth0,"brandColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"brandColor","hash":{},"data":data,"loc":{"start":{"line":6,"column":69},"end":{"line":6,"column":83}}}) : helper)))
    + ";\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"color:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fontColor") || (depth0 != null ? lookupProperty(depth0,"fontColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fontColor","hash":{},"data":data,"loc":{"start":{"line":9,"column":76},"end":{"line":9,"column":89}}}) : helper)))
    + ";\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"m-companyFactsBox__missionStatement\">\n                    <blockquote class=\"m-companyFactsBox__missionStatementText\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"missionStatement") || (depth0 != null ? lookupProperty(depth0,"missionStatement") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"missionStatement","hash":{},"data":data,"loc":{"start":{"line":12,"column":80},"end":{"line":12,"column":104}}}) : helper))) != null ? stack1 : "")
    + "</blockquote>\n                    <div class=\"m-companyFactsBox__description\">Unsere Mission</div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-companyFactsBox\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});