var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"m-companyFollowLoginInfo__container"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"direction") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":134}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNavItem") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":134},"end":{"line":3,"column":203}}})) != null ? stack1 : "")
    + "\">\n                <span class=\"m-companyFollowLoginInfo__text\">Bitte <a class=\"m-companyFollowLoginInfo__anchor\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"state") : depth0), depth0))
    + "\">melde dich an.</a></span>\n                <p class=\"m-companyFollowLoginInfo__close\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/cross--s.hbs"),depth0,{"name":"module/icon/files/dls/cross--s","hash":{"size":"large"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </p>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " m-companyFollowLoginInfo__container--"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"direction") : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    return " m-companyFollowLoginInfo__container--navitem";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-companyFollowLoginInfo\" data-direction=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"direction") : depth0), depth0))
    + "\" data-navitem=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"isNavItem") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"visible") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});