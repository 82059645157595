var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFilter") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"m-companyMediaItems__itemsWrapper\"\n             data-additionalitems=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"additionalItems") : depth0), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../slider/template.hbs"),depth0,{"name":"module/slider/template","hash":{"noFlex":true,"perMove":2,"rewind":true,"noSpaceBetween":true,"noMobile":true,"arrows":true,"active":false},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"m-companyMediaItems__lightboxSlides\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lightboxSlides") : depth0),{"name":"each","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":16},"end":{"line":83,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <ul class=\"m-companyMediaItems__filterButtons\">\n                <li class=\"m-companyMediaItems__filterBtn m-companyMediaItems__filterNone\">\n                    <span class=\"m-companyMediaItems__filterText"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNoFilter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":64},"end":{"line":7,"column":83}}})) != null ? stack1 : "")
    + "\">Fotos & Videos</span>\n                </li>\n                <li class=\"m-companyMediaItems__filterBtn m-companyMediaItems__filterImages\">\n                    <span class=\"m-companyMediaItems__filterText"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isImageFilter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":64},"end":{"line":11,"column":86}}})) != null ? stack1 : "")
    + "\">Fotos</span>\n                </li>\n                <li class=\"m-companyMediaItems__filterBtn m-companyMediaItems__filterVideos\">\n                    <span class=\"m-companyMediaItems__filterText"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isVideoFilter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":64},"end":{"line":15,"column":86}}})) != null ? stack1 : "")
    + "\">Videos</span>\n                </li>\n            </ul>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " m-companyMediaItems__filterText--active";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"slides") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":69,"column":25}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"m-slider__slide m-slider__slide--noSpaceBetween m-slider__slide--noMobile splide__slide\">\n                        <div class=\"m-companyMediaItems__slide\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mediaItems") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":66,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n                    </li>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a class=\"m-companyMediaItems__slideItem\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBig") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":29,"column":132}}})) != null ? stack1 : "")
    + "\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMoreItems") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":105}}})) != null ? stack1 : "")
    + "\"\n                                   data-image=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"previewSrc") : depth0), depth0))
    + "\"\n                                   data-mobilesrc=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"mobileSrc") : depth0), depth0))
    + "\"\n                                   data-tabletsrc=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"tabletSrc") : depth0), depth0))
    + "\"\n                                   data-subtitle=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\"\n                                   data-duration=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"duration") : depth0), depth0))
    + "\"\n                                   data-isbig=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"isBig") : depth0), depth0))
    + "\"\n                                   data-alt=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"alt") : depth0), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.program(16, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":44,"column":43}}})) != null ? stack1 : "")
    + ">\n\n                                    <img class=\"m-companyMediaItems__image m-companyMediaItems__image--slider\"\n                                         src=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"previewSrc") : depth0), depth0))
    + "\"\n                                         alt=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"alt") : depth0), depth0))
    + "\"\n                                         loading=\"lazy\"/>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMoreItems") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":51,"column":36},"end":{"line":64,"column":47}}})) != null ? stack1 : "")
    + "                                </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " m-companyMediaItems__slideItem--big";
},"10":function(container,depth0,helpers,partials,data) {
    return " m-companyMediaItems__slideItem--small";
},"12":function(container,depth0,helpers,partials,data) {
    return " m-companyMediaItems__slideItem--showMore";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                   data-mediatype=\"image\"\n                                   href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"tabletSrc") : depth0), depth0))
    + "\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                   data-mediatype=\"video\"\n                                   href=\"#\"\n                                    ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":40},"end":{"line":59,"column":51}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <p class=\"m-companyMediaItems__videoDetails\">\n                                                <span\n                                                    class=\"m-companyMediaItems__videoPlayIcon\">"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/play-button--s.hbs"),depth0,{"name":"module/icon/files/dls/play-button--s","hash":{"size":"xxl"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n                                                <span class=\"m-companyMediaItems__videoTitle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "</span>\n                                                <span class=\"m-companyMediaItems__videoDuration\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"duration") : depth0), depth0))
    + "</span>\n                                            </p>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"m-companyMediaItems__moreItemsHint\">\n                                            + "
    + container.escapeExpression(container.lambda((depths[2] != null ? lookupProperty(depths[2],"additionalItems") : depths[2]), depth0))
    + "\n                                        </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mediaItems") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":20},"end":{"line":82,"column":29}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"m-companyMediaItems__slideItem\"\n                             data-image=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"previewSrc") : depth0), depth0))
    + "\"\n                             data-mobilesrc=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mobileSrc") : depth0), depth0))
    + "\"\n                             data-tabletsrc=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tabletSrc") : depth0), depth0))
    + "\"\n                             data-subtitle=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\"\n                             data-mediatype=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":79,"column":45},"end":{"line":79,"column":85}}})) != null ? stack1 : "")
    + "\"\n                             "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":29},"end":{"line":80,"column":87}}})) != null ? stack1 : "")
    + ">\n                        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "image";
},"27":function(container,depth0,helpers,partials,data) {
    return "video";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-duration=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"duration") : depth0), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-companyMediaItems\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":86,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});