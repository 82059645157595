import { app } from 'app/app';
import { AppModule } from 'app/module';
import { Slider } from 'module/slider/main';
import { mediaQueries } from 'util/mediaQueries';
import deepClone from 'util/deepClone';
import Template from './template.hbs';
import './styles.scss';

const MAX_MEDIA_ITEMS = 9;
const MAX_SLIDE_ITEMS = 6;

export class CompanyMediaItems extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            mediaItems: ['.m-companyMediaItems__slideItem'],
            mediaItemImages: ['.m-companyMediaItems__image--slider'],
            itemsWrapper: '.m-companyMediaItems__itemsWrapper',
            filterButtons: '.m-companyMediaItems__filterButtons',
            filterNoneBtn: '.m-companyMediaItems__filterNone',
            filterImagesBtn: '.m-companyMediaItems__filterImages',
            filterVideosBtn: '.m-companyMediaItems__filterVideos',
            slider: '.m-slider',
        };
    }

    initInitialProps() {
        const active = !!this.dom.el.querySelector('.m-companyMediaItems__itemsWrapper');
        const additionalItems = this.dom.itemsWrapper
            ? parseInt(this.dom.itemsWrapper.getAttribute('data-additionalitems'), 10) : 0;
        const showFilter = !!this.dom.filterButtons;
        const props = {
            imagesProps: {
                slides: [],
                active,
                showFilter,
                isImageFilter: true,
            },
            videosProps: {
                slides: [],
                active,
                showFilter,
                isVideoFilter: true,
            },
            mediaItemsProps: {
                slides: [],
                active,
                showFilter,
                isNoFilter: true,
                count: parseInt(this.dom.mediaItems.length, 10),
                additionalItems,
            },
        };

        const mediaItems = [];
        const imageMediaItems = [];
        const videoMediaItems = [];

        for (let i = 0; i < this.dom.mediaItems.length; i += 1) {
            const $item = this.dom.mediaItems[i];
            const mediaItem = {
                isImage: $item.getAttribute('data-mediatype') === 'image',
                isBig: $item.getAttribute('data-isbig'),
                previewSrc: $item.getAttribute('data-image'),
                mobileSrc: $item.getAttribute('data-mobilesrc'),
                tabletSrc: $item.getAttribute('data-tabletsrc'),
                subtitle: $item.getAttribute('data-subtitle'),
                duration: $item.getAttribute('data-duration'),
                alt: $item.getAttribute('data-alt'),
            };
            mediaItems.push(deepClone(mediaItem));
            if (mediaItem.isImage) {
                imageMediaItems.push(deepClone(mediaItem));
            } else {
                videoMediaItems.push(deepClone(mediaItem));
            }
        }

        let imageCount = 0;
        let videoCount = 0;

        for (let i = 0; i < mediaItems.length; i += 1) {
            mediaItems[i].page = i + 1;

            if (mediaItems[i].isImage) {
                imageMediaItems[imageCount].page = imageCount + 1;
                imageCount += 1;
            } else {
                videoMediaItems[videoCount].page = videoCount + 1;
                videoCount += 1;
            }
        }

        // slides
        props.mediaItemsProps.slides = this.chunkArray(mediaItems);
        props.imagesProps.slides = this.chunkArray(imageMediaItems);
        props.videosProps.slides = this.chunkArray(videoMediaItems);

        // mediaItems
        props.mediaItemsProps.mediaItems = mediaItems;
        props.imagesProps.mediaItems = imageMediaItems;
        props.videosProps.mediaItems = videoMediaItems;

        // Add +additional items to last image
        props.imagesProps.additionalItems = imageMediaItems.length - MAX_MEDIA_ITEMS > 0
            ? imageMediaItems.length - MAX_MEDIA_ITEMS : 0;
        props.videosProps.additionalItems = videoMediaItems.length - MAX_MEDIA_ITEMS > 0
            ? videoMediaItems.length - MAX_MEDIA_ITEMS : 0;

        props.mediaItemsProps.slides = this.addMoreItems(props.mediaItemsProps.slides, additionalItems > 0);
        // eslint-disable-next-line max-len
        props.imagesProps.slides = this.addMoreItems(props.imagesProps.slides, imageMediaItems.length > MAX_MEDIA_ITEMS);
        // eslint-disable-next-line max-len
        props.videosProps.slides = this.addMoreItems(props.videosProps.slides, videoMediaItems.length > MAX_MEDIA_ITEMS);

        this.initialProps = props;
    }

    chunkArray(mediaItems) {
        const slides = [];
        let chunks = [];
        let count = 0;

        for (let i = 0; i < mediaItems.length - count; i = 0) {
            if (count % 3 === 1) {
                chunks = mediaItems.slice(count, count + 2);
                chunks[0].isBig = false;
                if (chunks[1]) {
                    chunks[1].isBig = false;
                }
                slides.push({ mediaItems: chunks });
                count += 2;
            } else {
                chunks = mediaItems.slice(count, count + 1);
                chunks[0].isBig = true;
                slides.push({ mediaItems: chunks });
                count += 1;
            }
        }

        return slides.slice(0, MAX_SLIDE_ITEMS);
    }

    addMoreItems(slides, shouldAdd = false) {
        if (shouldAdd) {
            const lastVisibleSlide = MAX_SLIDE_ITEMS - 1;

            slides[lastVisibleSlide].mediaItems[1].showMoreItems = true;
        }
        return slides;
    }

    initSlider() {
        this.slider?.destroy();
        if (!mediaQueries.isMobile()) {
            this.slider = new Slider(this.dom.slider);
            this.slider.setup();
            let lastSlideVisibleBefore = false;
            this.slider.on('moved', (newIndex, prevIndex) => {
                const lastSlide = this.slider.getSlidesDOMNodes()[this.slider.getSlideCount() - 1];
                const screenWidth = document.documentElement.clientWidth;
                const lastSlideVisible = lastSlide.getBoundingClientRect().right <= screenWidth;

                /* If last slide was already visible before, go back to start */
                if (prevIndex < newIndex && lastSlideVisibleBefore) {
                    this.slider.go(0);
                }

                /* If last slide is still visible after pressing back, go back one further */
                if (lastSlideVisible && prevIndex > newIndex) {
                    this.slider.go('-');
                }

                lastSlideVisibleBefore = lastSlideVisible;
            });
        }
    }

    domEvents() {
        if (this.dom.filterImagesBtn) {
            this.dom.filterImagesBtn.addEventListener('click', () => this.filterMediaItems('imagesProps'));
        }
        if (this.dom.filterNoneBtn) {
            this.dom.filterNoneBtn.addEventListener('click', () => this.filterMediaItems('mediaItemsProps'));
        }
        if (this.dom.filterVideosBtn) {
            this.dom.filterVideosBtn.addEventListener('click', () => this.filterMediaItems('videosProps'));
        }
        for (let i = 0; i < this.dom.mediaItems.length; i += 1) {
            this.dom.mediaItems[i].addEventListener('click', (e) => {
                e.preventDefault();
                this.openLightBox(e);
            });
        }
        app.events.on('onWindowResize', () => this.initSlider());
    }

    openLightBox(e) {
        const activeMediaItemSrc = e.target.getAttribute('data-mobilesrc');
        if (activeMediaItemSrc && !this.props.doNotOpenLightbox) {
            this.events.emit('openMediaItem', activeMediaItemSrc);
        }
        this.props.doNotOpenLightbox = false;
    }

    ready() {
        this.props.doNotOpenLightbox = false;
        this.initInitialProps();
        this.setProps(this.initialProps.mediaItemsProps, true);
        this.addViewportObserver();
        this.initSlider();
    }

    filterMediaItems(type) {
        this.setProps(this.initialProps[type], true);
        this.events.emit('changedFilter');
        this.initSlider();
    }
}
