var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"m-companyLocationMap__wrapper\">\n            <div class=\"m-companyLocationMap__staticMap\">\n"
    + ((stack1 = container.invokePartial(require("../staticMap/template.hbs"),(depth0 != null ? lookupProperty(depth0,"staticMap") : depth0),{"name":"module/staticMap/template","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-companyLocationMap__companyLocation\">\n"
    + ((stack1 = container.invokePartial(require("../companyLocation/template.hbs"),(depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0),{"name":"module/companyLocation/template","hash":{"insideIframe":(depth0 != null ? lookupProperty(depth0,"insideIframe") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"moreLocations") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"m-companyLocationMap__buttonContainer\">\n                <a class=\"m-companyLocationMap__button\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"locationsUrl") : stack1), depth0))
    + "\"\n                   data-event=\"click: all-locations\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"insideIframe") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":52},"end":{"line":15,"column":41}}})) != null ? stack1 : "")
    + ">Alle "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"numberOfLocations") : stack1), depth0))
    + " Standorte anzeigen</a>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "\n                   target=\"_blank\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-companyLocationMap\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"staticMap") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});