/**
 * @module util/scroll
 */

/**
 * Easing
 *
 * @param t
 */
function easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : ((t - 1) * ((2 * t) - 2) * ((2 * t) - 2)) + 1;
}

/**
 * Calculate scroll position
 *
 * @param start
 * @param end
 * @param elapsed
 * @param duration
 */
function position(start, end, elapsed, duration) {
    if (elapsed > duration) return end;
    return start + ((end - start) * easeInOutCubic(elapsed / duration));
}

/**
 * Scroll to specified end offset top
 *
 * @param end
 * @param duration
 */
function smoothScrollTo(end = 0, duration = 200) {
    const start = window.pageYOffset;
    const clock = Date.now();

    const step = () => {
        const elapsed = Date.now() - clock;
        window.scroll(0, position(start, end, elapsed, duration));

        if (elapsed <= duration) window.requestAnimationFrame(step);
    };
    step();
}

/**
 * Scroll to specified end offset top inside a specific div container
 * @param element
 * @param start
 * @param end
 * @param duration
 */
function smoothScrollInsideElement(element, start = 0, end = 0, duration = 200) {
    const clock = Date.now();
    const step = () => {
        const elapsed = Date.now() - clock;
        // eslint-disable-next-line
        element.scrollTop = position(start, end, elapsed, duration);

        if (elapsed <= duration) window.requestAnimationFrame(step);
    };
    step();
}

/**
 * Scroll to element offset top
 *
 * @param $element
 * @param duration
 * @param spacingTop
 */
function smoothScrollToElement($element, duration = 200, spacingTop = 0) {
    if (!$element) return;

    const offsetTop = $element.getBoundingClientRect().top + window.pageYOffset + spacingTop;

    smoothScrollTo(offsetTop, duration);
}

export { smoothScrollTo, smoothScrollToElement, smoothScrollInsideElement };
