var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-staticMap__inner m-staticMap__inner--placeholder\" data-tablet-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tablet") || (depth0 != null ? lookupProperty(depth0,"tablet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tablet","hash":{},"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":93}}}) : helper)))
    + "\"\n     data-mobile-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mobile") || (depth0 != null ? lookupProperty(depth0,"mobile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mobile","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":34}}}) : helper)))
    + "\">\n    <picture class=\"m-staticMap__pictureElement\">\n        <source\n            class=\"m-staticMap__mobileSrc\"\n            data-srcset=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mobile") || (depth0 != null ? lookupProperty(depth0,"mobile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mobile","hash":{},"data":data,"loc":{"start":{"line":6,"column":25},"end":{"line":6,"column":37}}}) : helper)))
    + "\"\n            media=\"(max-width:719px)\"\n            srcset=\"\">\n        <source\n            class=\"m-staticMap__tabletSrc\"\n            data-srcset=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tablet") || (depth0 != null ? lookupProperty(depth0,"tablet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tablet","hash":{},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":37}}}) : helper)))
    + "\"\n            media=\"(max-width: 1247px) and (min-width:720px)\"\n            srcset=\"\">\n        <source\n            class=\"m-staticMap__desktopSrc\"\n            data-srcset=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tablet") || (depth0 != null ? lookupProperty(depth0,"tablet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tablet","hash":{},"data":data,"loc":{"start":{"line":16,"column":25},"end":{"line":16,"column":37}}}) : helper)))
    + "\"\n            media=\"(min-width: 1248px)\"\n            srcset=\"\">\n        <img class=\"m-staticMap__image\"\n             data-latesrc=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tablet") || (depth0 != null ? lookupProperty(depth0,"tablet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tablet","hash":{},"data":data,"loc":{"start":{"line":20,"column":27},"end":{"line":20,"column":39}}}) : helper)))
    + "\"\n             src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\"\n             alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"companyName") || (depth0 != null ? lookupProperty(depth0,"companyName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"companyName","hash":{},"data":data,"loc":{"start":{"line":22,"column":18},"end":{"line":22,"column":33}}}) : helper)))
    + " Standorte\">\n        <noscript><img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tablet") || (depth0 != null ? lookupProperty(depth0,"tablet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tablet","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":38}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"companyName") || (depth0 != null ? lookupProperty(depth0,"companyName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"companyName","hash":{},"data":data,"loc":{"start":{"line":23,"column":45},"end":{"line":23,"column":60}}}) : helper)))
    + " Standorte\"/></noscript>\n    </picture>\n    <div class=\"m-staticMap__copyright\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"copyright") || (depth0 != null ? lookupProperty(depth0,"copyright") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"copyright","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});